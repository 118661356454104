.root {
  display: inline-block;
  align-items: center;
  width: 100%;
  border-radius: var(--border-radius);
  font-weight: var(--font-weight-regular);
}

// Modifiers
.root {
  &.sizeSmall {
    min-height: 35px;
    padding: 6px 10px;
    font-size: var(--font-size-15px, 15px);
    line-height: var(--line-height-for-15px);
  }

  &.sizeStandard {
    min-height: 42px;
    padding: 12px 20px;
    font-size: var(--font-size-16px, 16px);
    line-height: var(--line-height-for-16px);
  }

  &.variantPrimary {
    background-color: var(--primary-pale-color);
    color: var(--primary-alt-text-color);
  }

  &.variantSecondary {
    background-color: var(--secondary-pale-color);
    color: var(--secondary-alt-text-color);
  }

  &.variantSuccess {
    background-color: var(--success-pale-color);
    color: var(--success-alt-text-color);
  }

  &.variantDanger {
    background-color: var(--danger-pale-color);
    color: var(--danger-alt-text-color);
  }

  &.variantWarning {
    background-color: var(--warning-pale-color);
    color: var(--warning-alt-text-color);
  }

  &.variantInfo {
    background-color: var(--info-pale-color);
    color: var(--info-alt-text-color);
  }
}
