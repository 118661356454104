@use 'core/styles/breakpoints';

.root {
  margin-bottom: 50px;
}

.features {
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  @include breakpoints.above(tablet) {
    flex-direction: row;
    margin-top: 50px;
  }
}

a.feature {
  background-color: #fafafa;
  flex: 1;
  padding: 40px 20px;
  border-radius: var(--border-radius);
  text-align: center;
  font-weight: var(--font-weight-regular);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 20px 20px 20px;

  &:hover {
    background-color: var(--menu-item-background-hover-color);
    text-decoration: none;

    .ctaLink {
      text-decoration: underline;
    }
  }

  &:active {
    background-color: var(--menu-item-background-active-color);
  }

  @include breakpoints.above(tablet) {
    margin: 0 20px;

    &:first-of-type {
      margin-right: 10px;
    }

    &:last-of-type {
      margin-left: 10px;
    }
  }

  .heading {
    font-weight: var(--font-weight-extra-bold);
    font-size: var(--font-size-30px, 30px);
    letter-spacing: -1.3px;
    text-align: center;
    padding: 0;
    margin: 0;
  }

  .subheading {
    color: #8d8d8d;
    font-size: var(--font-size-18px, 18px);
    letter-spacing: -0.5px;
    line-height: var(--line-height-for-18px);
    margin-top: 10px;
    font-weight: var(--font-weight-medium);
  }

  .icon {
    width: 70px;
    height: 70px;
    margin: 30px 0;
  }

  .svgIcon {
    fill: #000;
  }

  .ctaLink {
    font-weight: var(--font-weight-bold);
  }
}

.noticeSection {
  padding: 0 20px 50px;
  margin-top: 50px;
  border-bottom: 1px solid var(--border-color);

  .heading {
    font-weight: var(--font-weight-extra-bold);
    font-size: var(--font-size-30px, 30px);
    letter-spacing: -1.3px;
    text-align: center;
    padding: 0;
    margin: 0;
    flex: 1;
  }
}

.creatorsSection {
  padding: 0 20px;
  margin-top: 60px;

  .heading {
    font-weight: var(--font-weight-extra-bold);
    font-size: var(--font-size-30px, 30px);
    letter-spacing: -1.3px;
    text-align: center;
    padding: 0;
    margin: 0;
    flex: 1;
  }
}

.creators {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  gap: 16px 16px;
  margin-top: 30px;
  clear: both;
}

.headingContainer {
  text-align: center;
}

.buttonRoot {
  margin-top: 16px;

  @include breakpoints.above(mobile) {
    float: right;
    margin-top: -42px;
  }
}

.noticeDescription {
  font-size: var(--font-size-16px, 16px);
  line-height: var(--line-height-for-16px);
  letter-spacing: -0.2px;
  margin: 20px auto;
  max-width: 500px;
  text-align: center;
}

.noticeButtons {
  text-align: center;
  margin-top: 40px;
}

.noticeButtonRoot {
  margin: 0 5px 20px;
}

.faqLink {
  margin-top: 20px;
  text-align: center;
}
